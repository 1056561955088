import React, { useState, useEffect } from 'react';
import { Route, Routes,useLocation} from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss';
import './assets/css/materialdesignicons.min.css';
import IndexSaas from './pages/index-saas';
import Error from './pages/page-error';
import ContactOne from './pages/page-contact-one';
import Refund from './componants/footer/Refund.jsx';
import Privacy from './componants/footer/Privacy.jsx';
import AdminLogin from './componants/AdminDashBoard/AdminLogin.jsx';
import CareerAdmin from './componants/AdminDashBoard/CareerAdmin.jsx';
import ContactAdmin from './componants/AdminDashBoard/ContactAdmin.jsx';
import UserSign from './componants/AdminDashBoard/UserSign.jsx';
// import ModalComponent from './componants/popup/ModalComponent.jsx';

function App() {
  // const { pathname } = useLocation();
  // const [showModal, setShowModal] = useState(false);

  // const handleClose = () => {
  //   setShowModal(false);
  //   localStorage.setItem("formSubmitted", "true");
  // };

  // const handleShow = () => setShowModal(true);

  // useEffect(() => {
  //   console.log('Pathname:', pathname);
  //   if (
  //     !localStorage.getItem("formSubmitted") &&
  //     !pathname.includes("/Admin-Page") &&
  //     pathname !== "/Admin-Login"
  //   ) {
  //     console.log('Showing modal in 1 second...');
  //     const timer = setTimeout(() => {
  //       handleShow();
  //     }, 1000);

  //     return () => clearTimeout(timer);
  //   }
  // }, [pathname]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  return (
    <>
      {/* {showModal && (
        <ModalComponent show={showModal} handleClose={handleClose} />
      )} */}
      <Routes>
        <Route path="/" element={<IndexSaas />} />
        <Route path="/page-error" element={<Error />} />
        <Route path="*" element={<Error />} />
        <Route path="/page-contact-one" element={<ContactOne />} />
        <Route path="/Refund" element={<Refund />} />
        <Route path="/Privacy" element={<Privacy />} />
        {/* Admin DashBoard Routing Start */}
        <Route path="/Admin-Login" element={<AdminLogin />} />
        <Route path="/Admin-Page-Career" element={<CareerAdmin />} />
        <Route path="/Admin-Page-Contact" element={<ContactAdmin />} />
        <Route path="/Admin-Page-UserSign" element={<UserSign />} />
        {/* Admin DashBoard Routing End */}
      </Routes>
    </>
  );
}

export default App;
