import React from "react";
import styled from "styled-components";

const Privacy = () => {
  return (
    <>
    <div></div>
      {/* <div className="container mt-5">
        <h1 className="mb-4 mt-5 text-center">Privacy Policy</h1>

        <div className="mt-5">
          <p className="text-secondary">
          DentalGuru InfoSystems. ("we" or "us") is the owner of dentalguru.software (collectively, the "Site" or "Sites"). ("Websites" and each, individually a "Website"). At DentalGuru, we are deeply committed to respecting your privacy and safeguarding your personal data.

This Privacy Policy may be updated from time to time without prior notice. We encourage you to review this policy periodically to stay informed about how we are protecting your information. This document explains how we collect, use, share, and protect your personal data as you interact with our Websites.
        
            <br/><br/>
            <h3 className="text-black">1. Introduction
            </h3>
            <p>DentalGuru’s Privacy Policy aligns with :
              <li className="mt-2"><b>Section 43A of the IT Act, 2000</b></li>
              <li className="mt-2"><b>Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 </b>(the "SPI Rules")</li>
            <li className="mt-2"><b>Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011</b></li>
            </p>
            <br/>
            <h3 className="text-black">2. Personal Information We Collect</h3>
            <p>At DentalGuru, we are the sole owner of the information collected through our Websites. We are committed to transparency and will not sell, share, transfer, or rent your personal information in ways that are different from what is disclosed in this Privacy Policy.</p>
            <h6>Types of Personal Information We Collect</h6>
            <li><b>Contact Information : </b>Name, email address, and phone number collected during the "Sign Up" and "Login" processes.</li>
            <li className="mt-2"><b>Sensitive Personal Data : </b>Includes data such as health conditions, medical history, and financial information only when necessary and with your explicit consent.</li>
            <br/>
            <h6><b>Sensitive Personal Data and Information (SPI)</b> as defined under SPI Rules includes : </h6> 
            <li>Passwords</li>
            <li>Financial details (bank accounts, card numbers)</li>
            <li>Health information (physical, physiological, mental conditions)</li>
            <li>Sexual orientation</li>
            <li>Medical records and history</li>
            <li>Information received under lawful contracts</li>
            <li>Visitor details (registration or updates)</li>
           <br/><br/>
           <h3 className="text-black">3. How We Use Your Personal Information</h3>
           <p>We use your information for various purposes, including : </p>
           <li><b>Service Provision : </b>To manage your account, provide services, and communicate relevant information.</li>
           <li><b>Security : </b>To detect and prevent fraudulent activities and to ensure the security of our Services.</li>
           <li><b> Improvement : </b>To enhance our website, services, and user experience based on your feedback and usage data.</li>
    
          <h6 className="mt-3">Consent and Data Collection</h6>
          <p>We collect sensitive information only with your explicit consent. You can withdraw your consent at any time by contacting us, which may affect the provision of our services.</p>
          <br/>
          <h3 className="text-black">4. Cookies and Tracking Technologies</h3>
          <p>We use cookies to improve user experience, track website activity, and manage promotional effectiveness. Cookies are small text files placed on your device to enhance functionality and gather information about your interactions with the site.</p>
          <h6>Cookie Uses : </h6>
          <li><b>Functionality : </b>To remember your login status and preferences.</li>
          <li><b>Analytics : </b>To analyze usage patterns and improve our service</li>
          <li><b>Advertising : </b>To deliver targeted content and measure ad performance.</li>

          <p className="mt-2">You can manage cookie preferences through your browser settings.</p>
          <br/>
          <h3 className="text-black">5. Your Responsibilities</h3> 
          <p>As a user, you are responsible for : 
            <li className="mt-2"><b>Accuracy : </b>Providing accurate and up-to-date personal information.</li>
            <li><b>Security : </b>Protecting your login credentials and reporting any suspicious activities.</li>
          </p>
          <p> If you provide false or incomplete information, we reserve the right to suspend or terminate your access to our services.</p>
          <br/> 
          <h3 className="text-black">6. Parental Supervision</h3>
          <p>DentalGuru is dedicated to providing a secure online environment for all users. We encourage parents and guardians to oversee their children’s online activities and use available parental control tools to monitor and limit access.</p>

          <h3 className="text-black">7. Data Security and Legal Obligations</h3>
          <p>We implement appropriate security measures to protect your personal information. However, we may disclose personal data if required by law or legal process.</p>
   
          <h3 className="text-black">8. Practitioner Information</h3>
          <p>Practitioners must provide accurate professional details, including qualifications and license numbers. We may publicly list your professional information for users to view, and any inaccuracies could lead to a suspension of services.</p>
          
          <h6><b>External Links : </b></h6>

          <p>We provide links to external sites for additional information. These sites are not controlled by DentalGuru, and we are not responsible for their privacy practices.</p>

          <h3 className="text-black">9. Contact Us</h3>
          <p>For questions or concerns regarding this Privacy Policy, please contact us at : </p>
          
          <h6><b>Email : </b>info@doaguru.com</h6>
          <h6><b>PHone : </b>+91-7440992424</h6>
          
          <h6>Address : </h6>
          <p>DentalGuru InfoSystms</p>
          <p></p>[1815 Write Town]
          <p></p>[Jabalpur,Madhya Pradesh,482001]
          <p></p>[India]
           
          <h4 className="mt-3 mb-5">Thank you for choosing DentalGuru InfoSystems</h4>
        
          </p>
          
        </div>
      </div> */}
    </>
  );
};

export default Privacy;
const Container = styled.div``;
